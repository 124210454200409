
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
}

h1 {
  cursor: default;
}

.customImage {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@media (min-width: 800px) {
  .Box:hover {
    min-width: 9rem;
  }
}

.Box {
  transition: .1s ease-in-out;
  min-width: 8rem;
  overflow: hidden;
  --color-canvas-default: #0d1117;
  --color-border-default: #515561;
  background-color: var(--color-canvas-default);
  border-color: var(--color-border-default);
  border-style: solid;
  border-width: var(--primer-borderWidth-thin, 1px);
}

.groupIconBlur {
    --brightness: brightness(70%);
    --tw-blur: blur(0.3px);
    --contrast: contrast(120%);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) var(--brightness) var(--contrast);
}

.dark h1 {
  background-color: transparent;
}

.light h1 {
  background-color: transparent;
}

.dark::selection {
  background: #ffeaea;
  color: #161212;
  border: white;
}

.light::selection {
  color: #ffeaea;
  border: black;
  background: #161212;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

.overParticles {
  z-index: 9999;
}

.dark {
  color: #ffeaea;
  background-color: #161212;
}

.light {
  background-color: #ffeaea;
  color: #161212;
}

.noLightDarkBackground {
  background: transparent;
}